.auth-wrapper {
    background-color: #F0F0F0;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    background-image: url('../../assets/images/xtnd-bg.png');
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;

    form {
        background-color: #fff;
        padding: 32px 40px;
        display: flex;
        flex-direction: column;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        ;
    }
}


.login-form {
    max-width: 500px;
    width:100%;
  }

  .login-form-margin {
    margin-bottom: 16px;
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
  }

.image-wrapper {
margin-bottom: 21px;
text-align: center;
}