/* Flag Icons */
@use "node_modules/flag-icons/css/flag-icons.min.css";

/* NG-ZORRO Imports */
@use "node_modules/ng-zorro-antd/ng-zorro-antd.min.css";

/* You can add global styles to this file, and also import other style files */
@use "./styles/custom/variables";
@use "./styles/custom/auth";
@use "./styles/custom/layout";
@use "./styles/custom/utilities";
